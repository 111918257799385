<template>
  <Modal
    :show-modal="showModal"
    :description="modalDescription"
    class="u-text-align--left"
    :class="modalClass"
    @close="() => emitAction('closeModal')"
  >
    <template
      v-if="signupModal || wishlistViewModal || removeTripModal"
      slot="heading"
    >
      <div class="u-text-align--center u-margin-bottom--2">
        <Imagery
          lazy-src="/files/images/logos/intrepid-logo.svg"
          alt="Intrepid Logo"
          type="static"
        ></Imagery>
      </div>
    </template>
    <template v-if="signupModal" slot="content">
      <p class="u-font-weight--bold">
        {{ wishlistModalText }}
      </p>
      <p>
        <span class="u-font-weight--bold">Create an account </span> so you can
        save your favorite trips for as long as you need.
      </p>
      <div
        class="
          l-grid
          u-margin-top--1
          l-grid--no-spacing
          u-flex-direction--column-reverse
          md:u-flex-direction--row
        "
      >
        <div class="wishlist-button-modal__button">
          <Button
            class="button button--secondary button--block"
            :onClick="() => emitAction('closeModal')"
            >Not now</Button
          >
        </div>
        <div class="wishlist-button-modal__button">
          <Button
            class="button button--primary button--block"
            :on-click="goToSignup"
            data-cy="wishlist-button-modal__go-to-signup"
            >Create my account</Button
          >
        </div>
      </div>
      <p class="u-margin-top--1 u-text-align--center">
        Already have a wishlist account?
        <a
          href="#"
          data-cy="wishlist-button-modal__go-to-login"
          @click.prevent="goToLogin"
          >Log in</a
        >
      </p>
    </template>
    <template v-if="wishlistViewModal" slot="content">
      <p class="u-font-weight--bold">
        {{ wishlistModalText }}
      </p>
      <p>
        View your favourite trips in your wishlist or keep exploring trips to
        add.
      </p>
      <div
        class="
          l-grid
          u-margin-top--1
          l-grid--no-spacing
          u-flex-direction--column-reverse
          md:u-flex-direction--row
        "
      >
        <div class="wishlist-button-modal__button">
          <Button
            class="button button--secondary button--block"
            :onClick="() => emitAction('closeModal')"
            data-cy="wishlist-button-modal__keep-exploring"
            >Keep exploring</Button
          >
        </div>
        <div class="wishlist-button-modal__button">
          <Button
            class="button button--primary button--block"
            :href="$link?.create(`/wishlist`)"
            data-cy="wishlist-button-modal__view-my-wishlist"
            >View my wishlist</Button
          >
        </div>
      </div>
    </template>
    <template v-if="removeTripModal" slot="content">
      <p class="u-font-weight--bold">
        Are you sure you want to remove this trip?
      </p>
      <p>
        You'll need to find and add this trip again to view it in your wishlist
      </p>
      <div
        class="
          l-grid
          u-margin-top--1
          l-grid--no-spacing
          u-flex-direction--column-reverse
          md:u-flex-direction--row
        "
      >
        <div class="wishlist-button-modal__button">
          <Button
            class="button button--secondary button--block"
            :onClick="() => emitAction('closeModal')"
            data-cy="wishlist-button-modal__cancel-button"
            >Not now</Button
          >
        </div>
        <div class="wishlist-button-modal__button">
          <Button
            class="button button--primary button--block"
            :onClick="() => emitAction('removeWishlistItem')"
            data-cy="wishlist-button-modal__remove-trip-button"
            >Remove trip</Button
          >
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import Vue from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
export default Vue.extend({
  name: "WishlistButtonModal",
  components: { Button, Modal, Imagery },
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    signupModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    wishlistViewModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    removeTripModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    wishlistModalText() {
      const count = this.$store.getters["wishlist/getWishlistCount"];
      return count === 1
        ? "Nice choice! You've added your first trip to your wishlist"
        : "Nice choice! You've added another trip to your wishlist";
    },
    modalDescription() {
      if (this.signupModal) {
        return "Wishlist signup modal";
      }
      if (this.wishlistViewModal) {
        return "Wishlist view modal";
      }
      if (this.removeTripModal) {
        return "Remove trip modal";
      }
      return "";
    },
    modalClass() {
      return this.wishlistViewModal ? "" : "modal--small";
    },
  },
  methods: {
    emitAction(action: string): void {
      this.$emit(action);
    },
    async goToSignup() {
      await this.$auth.loginWith("auth0", {
        params: { tab: "signup" },
      });
    },
    async goToLogin() {
      await this.$auth.loginWith("auth0", {
        params: { tab: "login" },
      });
    },
  },
});
</script>
<style lang="scss">
@import "./wishlist-button-modal";
</style>
